import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        You can have this domain,
        <a
          className="App-link"
          href="https://sedo.com/search/?keyword=bkng.group&language=us"
          target="_blank"
          rel="https://sedo.com/search/?keyword=bkng.group&language=us"
        >
          Buy it
        </a>
        Or reach me at bkngsell@gmail.com
      </header>
    </div>
  );
}

export default App;
